<template>
    <ORowContainer appContainer :noToolbar="props.noToolbar" class="bg-light" style="overflow-y:auto">
        <div v-bind="$attrs" class="px-md-5 py-md-2" :class="{ 'container': !props.fluid, 'container-fluid': props.fluid }">                
            <div v-if="props.showLoadingSpinner" id="app-loading-spinner" class="overlay"> 
                <div class="overlay__inner">
                    <div class="overlay__content"><span class="spinner"></span></div>
                </div>
            </div>
            <div class="pb-1">
                <slot v-if="!props.showLoadingSpinner" :props="props"></slot>
            </div>
        </div>
    </ORowContainer>    
</template>

<script setup lang="ts">
    import ORowContainer from 'o365.vue.components.RowContainer.vue';
    export interface Props {
        isEdit?: boolean,
        showLoadingSpinner?: boolean,
        noToolbar?: boolean,
        fluid?: boolean
    }
    const props = withDefaults(defineProps<Props>(),{
        isEdit: false,
        showLoadingSpinner: false,
        noToolbar: true,
        fluid: false
    })
</script>

<style scoped>
    @media (max-width:480px){
        .container,.container-fluid{
            padding-right: 5px!important;
            padding-left: 5px!important;
        }
    }
</style>